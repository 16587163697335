import Logo from 'assets/logos/tutortime.png';

export const textColors = {
  primary: '#22242D',
  secondary: '#4E5057',
};

export default {
  logo: Logo,
  baseUrl: process.env.NEXT_PUBLIC_WP_URL,
  searchTitle: 'Explore Teachers',
  searchSubTitle: 'To book a particular teacher, click Book',
  profileTitle: 'Teacher Profile',
  primary: {
    main: '#005EFB',
    dark: '#1257CB',
    contrastText: '#fff',
  },
  secondary: {
    main: '#EFA526',
  },
  success: {
    main: '#59DDB0',
  },
  orange: '#F7B432',
  light: '#d9e7ff',
  background: {
    main: '#fff',
  },
  textPrimary: {
    main: textColors.primary,
  },
  textSecondary: {
    main: textColors.secondary,
  },
  text: textColors,
};
