import { window } from 'window-or-global';
import mixpanel from 'mixpanel-browser';
import google from 'react-ga';
import axios from 'config/axios';
import Events from './events';

class Analytics {
  static mixpanelKey = process.env.NEXT_PUBLIC_MIXPANEL_KEY;

  static environment = process.env.NEXT_PUBLIC_ENVIRONMENT;

  static get distinctId() {
    return this.id;
  }

  static set distinctId(value) {
    this.id = value;
  }

  static init = () => {
    mixpanel.init(this.mixpanelKey, {
      loaded: mixpanel => {
        this.distinctId = mixpanel.get_distinct_id();
      },
    });

    if (this.environment === 'production') {
      google.initialize('UA-145664428-2');
    }
  }

  static pageView = () => {
    if (this.environment === 'production') {
      google.pageview(window.location.pathname + window.location.search);
    }
  }

  static customPageView = page => {
    if (this.environment === 'production') {
      google.pageview(page);
    }
  }

  static track = (event, data = {}) => {
    mixpanel.track(event, {
      ...data,
      Platform: 'web',
    });
  }

  static trackExternal = async (event, data = {}) => {
    try {
      await axios.post('events', {
        event,
        data: {
          ...data,
          distinct_id: this.distinctId,
          Platform: 'web',
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  static composeProfile = (data = {}) => {
    const phone = data.mobileNumber ? `${data.countryCode}${data.mobileNumber}` : undefined;
    return {
      $email: data.email,
      $name: `${data.firstName} ${data.lastName}`,
      user_id: data.id,
      user_type: 'user',
      phone,
    };
  }

  static alias = (id, distinct) => {
    mixpanel.alias(id, distinct);
  }

  static aliasThenIdentify = data => {
    if (!data || !data.id) return;

    const { id } = data;
    this.setData(data);
    this.alias(id, this.distinctId);
  }

  static setData = data => {
    mixpanel.people.set({
      $email: data.email,
      $name: `${data.firstName} ${data.lastName}`,
      user_id: data.id,
      user_type: 'user',
      phone: this.getPhoneNumber(data),
    });
  }

  static getPhoneNumber = data => {
    const code = data.countryCode || '';
    const number = data.mobileNumber || '';

    return `${code}${number}`;
  }
}

export { Events };
export default Analytics;
