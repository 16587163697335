export default {
  INITIATED_CHECKOUT: 'fb_mobile_initiated_checkout',
  ADDED_PAYMENT_INFO: 'fb_mobile_add_payment_info',
  VIEWED_TUTOR_PROFILE: 'Viewed Tutor Profile',
  SEARCH_TUTOR: 'Search for Tutor',
  VIEWED_SIGN_UP: 'Viewed Signup Details',
  VIEWED_PAYMENT_PAGE: 'Viewed Payment Page',
  SELECTED_GRADES: 'Selected Grades',
  SELECTED_CATEGORIES: 'Selected Categories',
  SELECTED_SUBJECTS: 'Selected Subjects',
  SELECTED_LOCATION: 'Selected Location',
  VIEWED_SUCCESS: 'Viewed Success Page',
  MADE_BOOKING: 'Made Booking Request',
  APP_DOWNLOAD: 'Clicked App Download',
};
