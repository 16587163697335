import TutorTime from './tutortime';
import Kambala from './kambala';
import Teachers from './teachers';

const palettes = {
  0: TutorTime,
  1: Kambala,
  2: Teachers,
};

export const getPalette = (index = 0) => {
  return !palettes[index] ? palettes[0] : palettes[index];
};
