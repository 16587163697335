import Logo from 'assets/logos/kambala.svg';

const textColors = {
  primary: '#22242D',
  secondary: '#4E5057',
};

export default {
  logo: Logo,
  baseUrl: 'https://kambala.nsw.edu.au/',
  searchTitle: 'Search all K-12 Mentors / Homework Helpers / Babysitters / Dog Walkers / Pet Minders',
  searchSubTitle: 'To book a particular ex-student, click Book',
  profileTitle: 'Mentor / Homework Helper / Babysitter / Dog Walker / Pet Minder',
  primary: {
    main: '#f9b416',
    contrastText: '#fff',
  },
  secondary: {
    main: '#EFA526',
  },
  success: {
    main: '#59DDB0',
  },
  orange: '#F7B432',
  light: '#fffaef',
  background: {
    main: '#fff',
  },
  textPrimary: {
    main: textColors.primary,
  },
  textSecondary: {
    main: textColors.secondary,
  },
  text: textColors,
};
