import { getPalette } from './palette';

const breakpoints = {
  xs: 0,
  sm: 540,
  md: 768,
  lg: 1140,
  xl: 1440,
};

class Theme {
  constructor() {
    this._themeIndex = 0;
    this._palette = getPalette(this._themeIndex);
  }

  set themeIndex(themeIndex) {
    if (themeIndex === null) return;

    console.debug('Theme index:', themeIndex);
    this._themeIndex = themeIndex;
    this._palette = getPalette(themeIndex);
  }

  get themeIndex() {
    return this._themeIndex;
  }

  get palette() {
    return this._palette;
  }

  get theme() {
    const { palette } = this;

    return {
      typography: {
        fontFamily: 'Lato, Helvetica, sans-serif',
      },
      palette: {
        ...palette,
        status: {
          pending: '#efa526',
          accepted: '#31cc6a',
          cancelled: '#fa323c',
        },
      },
      breakpoints: {
        values: breakpoints,
      },
      overrides: {
        MuiContainer: {
          maxWidthLg: {
            '@media (min-width: 1140px)': {
              maxWidth: 1188,
            },
          },
        },
        MuiTypography: {
          h1: {
            color: palette.primary.main,
            fontSize: 14,
            lineHeight: 1.21428571,
            marginBottom: 16,
            fontWeight: 900,
            '@media (min-width: 768px)': {
              fontSize: 24,
              lineHeight: 1.208,
              paddingLeft: 20,
            },
          },
          h2: {
            fontSize: 10,
            marginTop: 12,
            marginBottom: 12,
            fontWeight: 700,
            '@media (min-width: 768px)': {
              fontSize: 18,
              paddingLeft: 20,
            },
          },
        },
        MuiButton: {
          root: {
            textTransform: 'none',
          },
          contained: {
            borderRadius: 40,
            fontWeight: 900,
          },
        },
        MuiCssBaseline: {
          '@global': {
            'strong, b': {
              fontWeight: 900,
            },
            '.rmc-picker-item': {
              color: palette.text.primary,
              height: 34,
              fontSize: 16,
              lineHeight: 2.125,
            },
          },
        },
      },
    };
  }
}

const theme = new Theme();
export default theme;
