import { snakeCase } from 'snake-case';
import { camelCase } from 'camel-case';

const casings = {
  camelCase,
  snakeCase,
};

export const objectToCase = (o, casing = 'snakeCase') => {
  const transform = casings[casing];
  const keys = Object.keys(o);
  const result = keys.reduce((total, current) => {
    const key = transform(current);
    return {
      ...total,
      [key]: o[current],
    };
  }, {});
  return result;
};
