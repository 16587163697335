class Cookies {
  /*
   * `get` is reserved in this namespace
   */
  static read(name) {
    const matches = document.cookie.match(new RegExp(
      // eslint-disable-next-line
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  static write(name, value) {
    document.cookie = `${name}=${value}`;
  }

  static delete(name) {
    if (this.read(name)) {
      document.cookie = `${name}=""; max-age=-1`;
    }
  }
}

export default Cookies;
