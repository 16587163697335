import axios, { fetchAPI } from 'config/axios';
import Analytics from 'helpers/analytics';
import Cookies from 'helpers/cookies';
import { objectToCase } from 'helpers/casing';
import {
  SET_PROFILE,
  RESET_PROFILE,
  TOGGLE_SIGN_IN_BOOKING,
  SET_LOADING,
} from './actionTypes';

export const signup = (body, successCallback, errorCallback) => async dispatch => {
  const { distinctId } = Analytics;
  try {
    const response = await axios.post('auth/signup', {
      ...body,
      distinctId,
    });
    dispatch({
      type: SET_PROFILE,
      payload: response.data,
    });
    Analytics.identify(distinctId, response.data);
    const { accessToken } = response.data;
    Cookies.write('tta', accessToken);

    if (successCallback && typeof successCallback === 'function') {
      successCallback(response.data);
    }
  } catch (e) {
    console.error(e);
    if (e.response?.data) {
      const { message } = e.response.data;
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(message);
      }
    }
  }
};

export const signin = (body, successCallback, errorCallback) => async dispatch => {
  try {
    const response = await axios.post('auth/signin', {
      ...body,
      userType: '1',
    });
    dispatch({
      type: SET_PROFILE,
      payload: response.data,
    });
    const { accessToken } = response.data;
    Cookies.write('tta', accessToken);

    if (successCallback && typeof successCallback === 'function') {
      successCallback(response.data);
    }
  } catch (e) {
    console.error(e);
    if (e.response?.data) {
      const { message } = e.response.data;
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback(message);
      }
    }
  }
};

export const toggleSignInBooking = () => async dispatch => {
  dispatch({
    type: TOGGLE_SIGN_IN_BOOKING,
  });
};

export const resetPassword = ({ email }, successCallback, errorCallback) => async () => {
  const body = objectToCase({
    securityKey: process.env.NEXT_PUBLIC_RAILS_SECURITY_KEY,
    email,
    userType: '1',
  }, 'snakeCase');

  try {
    const response = await fetchAPI.post('v2/registers/forgot_password', body);
    const { code: statusCode, message = '' } = response.data;
    if (statusCode !== 200) {
      throw Error(message);
    }
    if (successCallback && typeof successCallback === 'function') {
      successCallback(message);
    }
  } catch (e) {
    console.error(e);
    errorCallback(e.message);
  }
};

export const checkAuth = () => async dispatch => {
  const token = Cookies.read('tta');
  if (token) {
    try {
      const response = await axios.get(`profile?token=${token}`);
      dispatch({
        type: SET_PROFILE,
        payload: response.data,
      });
      return response.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  dispatch({ type: SET_LOADING, payload: false });
  return null;
};

export const logout = () => async dispatch => {
  dispatch({ type: RESET_PROFILE });
  Cookies.delete('tta');
};
